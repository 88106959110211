document.addEventListener('DOMContentLoaded', function() {
    const offset = 50; // Change this value to your desired offset
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function(e) {
            e.preventDefault();
            const target = document.querySelector(this.getAttribute('href'));
            if (target) {
                const topPosition = target.getBoundingClientRect().top + window.scrollY - offset;
                window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth'
                });
            }
        });
    });
});