document.addEventListener('DOMContentLoaded', function() {
    const form = document.querySelector('.product-variants-form');
    
    const totalPriceSpan = document.querySelector('.total-price');
    const totalQuantitySpan = document.querySelector('.total-quantity');
    
    function updateTotals() {
        const quantityInputs = form.querySelectorAll('.quantity-input');
        let totalUnits = 0;
        let totalPrice = 0;
        let totalQuantity = 0;
        
        quantityInputs.forEach(input => {
            const quantity = parseInt(input.value) || 0;
            const units = parseInt(input.dataset.units) || 0;
            const price = parseFloat(input.dataset.price) || 0;
            
            totalUnits += units * quantity;
            totalPrice += price * quantity;
            totalQuantity += quantity;
        });
        
        totalPriceSpan.textContent = formatPrice(totalPrice);
        totalQuantitySpan.textContent = totalQuantity;
        console.log(totalQuantity);
    }
    
    function formatPrice(price) {
        return new Intl.NumberFormat('en-NZ', {
            style: 'currency',
            currency: 'NZD'
        }).format(price);
    }
    
    if (form) {
        form.addEventListener('input', function(e) {
            if (e.target.classList.contains('quantity-input')) {
                console.log('input');
                updateTotals();
            }
        });
        form.addEventListener('click', function(e) {
            console.log(e.target);
            if (e.target.closest('.quantity-btn')) {
                console.log('click');
                setTimeout(updateTotals, 0);
            }
        });
        // Initial calculation
        // updateTotals();
    }

    // Add favorites functionality
    document.querySelectorAll('.favourites-button-container').forEach(container => {
        const button = container.querySelector('.favourite-button');
        // Check if we're in the favorites panel
        const isInFavoritesPanel = container.closest('.favourites') !== null;
        
        button.addEventListener('click', function() { 
            const data = {
                elementId: container.dataset.variantId,
                elementSiteId: container.dataset.siteId,
                action: container.dataset.action
            };

            // Add CSRF token
            data[container.dataset.csrfName] = container.dataset.csrfToken;

            fetch('/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: new URLSearchParams(data)
            })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // Toggle button state
                    button.classList.toggle('active');
                    // Update action for next click
                    container.dataset.action = container.dataset.action === 'wishlist/items/add' 
                        ? 'wishlist/items/remove' 
                        : 'wishlist/items/add';


                    // If in favorites panel and removing item, remove the product card from view
                    if (isInFavoritesPanel && container.dataset.action === 'wishlist/items/add') {
                        const productCard = container.closest('.favourites__item');
                        if (productCard) {
                            productCard.style.opacity = '0';
                            setTimeout(() => {
                                productCard.remove();
                                
                                // Check if there are any remaining favorite items
                                const remainingItems = document.querySelectorAll('.favourites__item');
                                if (remainingItems.length === 0) {
                                    const favouritesContainer = document.querySelector('.favourites');
                                    if (favouritesContainer) {
                                        const emptyMessage = document.createElement('p');
                                        emptyMessage.textContent = "You have no favourites yet";
                                        emptyMessage.className = 'favourites-empty-message';
                                        favouritesContainer.appendChild(emptyMessage);
                                    }
                                }
                            }, 300); // Smooth transition before removal
                        }
                    }
                    if(response.action === 'add') {
                        showToast("Added to favourites");
                    } else {
                        showToast("Removed from favourites");
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        });
    });

    const flashMessageElement = document.getElementById("flash-message");
    if (flashMessageElement) {
        const successMessage = flashMessageElement.dataset.message;
        if (successMessage && successMessage.trim() !== "") {
            showToast(successMessage);
        }
    }
    const flashMessageErrorElement = document.getElementById("flash-message-error");
    if (flashMessageErrorElement) {
        const errorMessage = flashMessageErrorElement.dataset.message;
        if (errorMessage && errorMessage.trim() !== "") {
            showToast(errorMessage, true);
        }
    }
    function showToast(message, isError = false) {
        const toast = document.createElement("div");
        toast.className = isError ? "toast-notification error" : "toast-notification";
        toast.textContent = message;

        document.body.appendChild(toast);
        console.log(toast);
        setTimeout(() => {
            toast.classList.add("fade-out");
            setTimeout(() => toast.remove(), 500);
        }, 3000);
    }
});

// document.body.addEventListener("htmx:afterRequest", function (event) {
//     setTimeout(() => {
//         const flashMessageElement = document.getElementById("flash-message");
//         const flashMessageErrorElement = document.getElementById("flash-message-error");
//         if (flashMessageElement) {
//             const successMessage = flashMessageElement.dataset.message;
//             if (successMessage && successMessage.trim() !== "") {
//                 showToast(successMessage);
//             }
//         }
//         if (flashMessageErrorElement) {
//             const errorMessage = flashMessageErrorElement.dataset.message;
//             if (errorMessage && errorMessage.trim() !== "") {
//                 showToast(errorMessage, true);
//             }
//         }
//     }, 100); // Small delay to allow DOM update

//     function showToast(message, isError = false) {
//         const toast = document.createElement("div");
//         toast.className = isError ? "toast-notification error" : "toast-notification";
//         toast.textContent = message;

//         document.body.appendChild(toast);

//         setTimeout(() => {
//             toast.classList.add("fade-out");
//             setTimeout(() => toast.remove(), 500);
//         }, 3000);
//     }
// });


