document.addEventListener('DOMContentLoaded', function() {
    const modal = document.getElementById('enquiry-modal');
    const closeButton = document.getElementById('modal-close');
    const enquiryButtons = document.querySelectorAll('.product-enquiry-open');

    if (!modal || !closeButton || enquiryButtons.length === 0) {
        console.warn('Modal elements not found');
        return;
    }

    function openModal(event) {
        event.preventDefault();
        const button = event.currentTarget;
        const variantCode = button.getAttribute('data-variant-code') || '';
        const codeInput = document.getElementsByName('fields[sku]')[0];

        if (codeInput) {
            codeInput.value = variantCode;
        }

        modal.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        modal.classList.add('hidden');
        document.body.style.overflow = '';
        const codeInput = document.getElementsByName('fields[sku]')[0];
        if (codeInput) {
            codeInput.value = '';
        }
    }
    enquiryButtons.forEach(button => {
        button.addEventListener('click', openModal);
    });

    closeButton.addEventListener('click', closeModal);
    document.addEventListener('keydown', function(e) {
        if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
            closeModal();
        }
    });
});
