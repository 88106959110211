document.addEventListener('DOMContentLoaded', function() {
    const businessPersonalTab = document.getElementById('business-personal');
    const tradeAccountTab = document.getElementById('trade-account');
    const businessPersonalForm = document.querySelector('.business-personal-form');
    const tradeAccountForm = document.querySelector('.trade-account-form');

    // Check if all required elements exist
    if (!businessPersonalTab || !tradeAccountTab || !businessPersonalForm || !tradeAccountForm) {
        return;
    }

    function switchTabs(showBusiness) {
        if (showBusiness) {
            businessPersonalForm.classList.add('active');
            tradeAccountForm.classList.remove('active');
            businessPersonalTab.checked = true;
            // Store state
            localStorage.setItem('activeRegistrationTab', 'business');
        } else {
            businessPersonalForm.classList.remove('active');
            tradeAccountForm.classList.add('active');
            tradeAccountTab.checked = true;
            // Store state
            localStorage.setItem('activeRegistrationTab', 'trade');
        }
    }

    // Restore last active tab
    const lastActiveTab = localStorage.getItem('activeRegistrationTab');
    if (lastActiveTab === 'trade') {
        switchTabs(false);
    } else {
        switchTabs(true);
    }

    businessPersonalTab.addEventListener('change', () => switchTabs(true));
    tradeAccountTab.addEventListener('change', () => switchTabs(false));

    // Clear stored tab on successful registration
    // This assumes there's a success message or redirect
    // You might need to adjust this based on your success criteria
    if (window.location.href.includes('thank-you-for-registering')) {
        localStorage.removeItem('activeRegistrationTab');
    }
}); 