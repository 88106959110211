import gsap from 'gsap';
document.addEventListener('DOMContentLoaded', () => {
    const dropdownTriggers = document.querySelectorAll('[data-dropdown-trigger]');
    
    dropdownTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            const content = trigger.nextElementSibling;
            const isExpanded = trigger.getAttribute('aria-expanded') === 'true';
            
            // Toggle the dropdown
            trigger.setAttribute('aria-expanded', !isExpanded);
            content.classList.toggle('active');
        });
        
        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!trigger.contains(e.target)) {
                trigger.setAttribute('aria-expanded', 'false');
                trigger.nextElementSibling.classList.remove('active');
            }
        });
    });

    const cartDrawer = document.querySelector('.cart button');
    const cartCloseButton = document.querySelector('.cart-drawer-container .cart-drawer-close-btn');
    cartDrawer.addEventListener('click', () => {
        gsap.to('.cart-drawer-container', {
            right: 0,
            duration: 0.5,
        });
    });
    cartCloseButton.addEventListener('click', () => {
        gsap.to('.cart-drawer-container', {
            right: '-100%',
            duration: 0.5,
        });
    });
});