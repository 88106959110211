function shareContent(e) {
    e.preventDefault();
    
    const shareData = {
        title: document.title,
        text: document.querySelector('meta[name="description"]')?.content || '',
        url: window.location.href
    };

    if (navigator.share) {
        navigator.share(shareData)
            .then(() => console.log('Successfully shared'))
            .catch((error) => {
                console.log('Error sharing:', error);
                // Fallback to clipboard if sharing fails
                fallbackToClipboard();
            });
    } else {
        fallbackToClipboard();
    }
}

function fallbackToClipboard() {
    navigator.clipboard.writeText(window.location.href)
        .then(() => alert('Link copied to clipboard!'))
        .catch((error) => console.log('Error copying:', error));
}

// Add event listener to share buttons
document.addEventListener('DOMContentLoaded', () => {
    const shareButtons = document.querySelectorAll('.news-single__share-button a');
    shareButtons.forEach(button => {
        button.addEventListener('click', shareContent);
    });
});

// Export for use in other modules if needed
export { shareContent };
