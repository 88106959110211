document.addEventListener('DOMContentLoaded', function() {
    console.log('=== Debug Form Elements ===');
    
    // Log all phone inputs in both forms
    document.querySelectorAll('input[type="tel"]').forEach(input => {
        console.log('Found tel input:', {
            name: input.name,
            form: input.closest('form')?.id,
            container: input.closest('.register-page__right-form')?.classList.toString()
        });
    });

    const tradeFormContainer = document.querySelector('.trade-account-form');
    const tradeForm = tradeFormContainer?.querySelector('form');
    
    if (tradeForm) {
        // Get phone and postcode inputs
        const phoneInputs = tradeForm.querySelectorAll('input[type="tel"]');
        const postcodeInputs = tradeForm.querySelectorAll('input[name="fields[billingAddress][zip]"], input[name="fields[shippingAddress][zip]"]');
        
        console.log('Trade form container:', tradeFormContainer);
        console.log('Trade form:', tradeForm);
        console.log('Trade phone inputs:', phoneInputs);
        console.log('Trade postcode inputs:', postcodeInputs);

        // Phone validation setup
        phoneInputs.forEach(phoneInput => {
            phoneInput.addEventListener('input', function(e) {
                // Validate on input if the field has a value
                if (e.target.value) {
                    validatePhoneNumber({ target: e.target });
                }
            });
            
            phoneInput.addEventListener('blur', function(e) {
                // Only validate on blur if the field has a value
                if (e.target.value) {
                    validatePhoneNumber({ target: e.target });
                }
            });
        });

        // Postcode validation setup
        postcodeInputs.forEach(postcodeInput => {
            postcodeInput.addEventListener('blur', function(e) {
                // Only validate on blur if the field has a value
                if (e.target.value) {
                    validatePostcode({ target: e.target });
                }
            });
        });

        // Add direct form submission handler
        tradeForm.addEventListener('submit', function(e) {
            let isValid = true;
            
            // Validate filled postcodes
            postcodeInputs.forEach(input => {
                if (input.value && input.value.length > 10) {
                    isValid = false;
                    validatePostcode({ target: input });
                }
            });

            // Validate filled phone numbers
            phoneInputs.forEach(input => {
                if (input.value && !isValidPhoneNumber(input.value)) {
                    isValid = false;
                    validatePhoneNumber({ target: input });
                }
            });

            if (!isValid) {
                console.log('Form validation failed - preventing submission');
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            
            console.log('Form validation passed');
        }, true);
    }

    function countDigits(value) {
        // Count only numeric characters
        return (value.match(/\d/g) || []).length;
    }

    function isValidPhoneNumber(value) {
        return countDigits(value) >= 7;
    }

    function validatePhoneNumber(e) {
        const input = e.target;
        const value = input.value;
        const fieldContainer = input.closest('.fui-field');
        const errorDiv = fieldContainer.querySelector('.fui-error-message') || 
                        createErrorDiv(fieldContainer);
        
        const digitCount = countDigits(value);
        console.log('Validating phone:', { value, digitCount });

        if (value && digitCount < 7) {
            fieldContainer.classList.add('fui-error');
            input.classList.add('fui-error');
            errorDiv.textContent = 'Please enter at least 7 digits';
            input.setAttribute('aria-invalid', 'true');
            return false;
        } else {
            fieldContainer.classList.remove('fui-error');
            input.classList.remove('fui-error');
            errorDiv.textContent = '';
            input.setAttribute('aria-invalid', 'false');
            return true;
        }
    }

    function validatePostcode(e) {
        const input = e.target;
        const value = input.value;
        const fieldContainer = input.closest('.fui-field');
        const errorDiv = fieldContainer.querySelector('.fui-error-message') || 
                        createErrorDiv(fieldContainer);
        
        console.log('Validating postcode:', { value, length: value.length });
        
        if (value.length > 10) {
            fieldContainer.classList.add('fui-error');
            input.classList.add('fui-error');
            errorDiv.textContent = 'Post code cannot exceed 10 characters';
            input.setAttribute('aria-invalid', 'true');
            return false;
        } else {
            fieldContainer.classList.remove('fui-error');
            input.classList.remove('fui-error');
            errorDiv.textContent = '';
            input.setAttribute('aria-invalid', 'false');
            return true;
        }
    }

    function createErrorDiv(fieldContainer) {
        const errorDiv = document.createElement('div');
        errorDiv.className = 'fui-error-message';
        fieldContainer.appendChild(errorDiv);
        return errorDiv;
    }
}); 