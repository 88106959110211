class Accordion {
    constructor() {
        this.accordionItems = document.querySelectorAll('.accordian-block__item');
        this.init();
        // Check for active accordions on load
        this.checkActiveAccordions();
    }

    init() {
        this.accordionItems.forEach(item => {
            const title = item.querySelector('.accordian-block__title');
            const content = item.querySelector('.accordian-block__content');
            const contentInner = item.querySelector('.accordian-block__content-inner');

            // Set initial height to 0
            content.style.height = '0px';

            title.addEventListener('click', () => this.toggleAccordion(item, content, contentInner));
        });
    }

    toggleAccordion(item, content, contentInner) {
        const isOpen = item.classList.contains('active');
        
        // Close all other accordions
        this.accordionItems.forEach(otherItem => {
            if (otherItem !== item && otherItem.classList.contains('active')) {
                const otherContent = otherItem.querySelector('.accordian-block__content');
                otherItem.classList.remove('active');
                otherContent.style.height = '0px';
                otherItem.querySelector('.accordian-block__title svg').style.transform = 'rotate(0deg)';
            }
        });

        // Toggle current accordion
        if (!isOpen) {
            item.classList.add('active');
            content.style.height = contentInner.offsetHeight + 'px';
            item.querySelector('.accordian-block__title svg').style.transform = 'rotate(45deg)';
        } else {
            item.classList.remove('active');
            content.style.height = '0px';
            item.querySelector('.accordian-block__title svg').style.transform = 'rotate(0deg)';
        }
    }

    checkActiveAccordions() {
        this.accordionItems.forEach(item => {
            if (item.classList.contains('active')) {
                const content = item.querySelector('.accordian-block__content');
                const contentInner = item.querySelector('.accordian-block__content-inner');
                content.style.height = contentInner.offsetHeight + 'px';
                item.querySelector('.accordian-block__title svg').style.transform = 'rotate(45deg)';
            }
        });
    }
}

// Initialize accordion when DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
    new Accordion();
});
