document.addEventListener('DOMContentLoaded', function() {
    const orderNumberInput = document.getElementById('order-number');
    const showSelect = document.getElementById('show');
    const ordersTable = document.querySelector('.orders-table');
    const orderRows = document.querySelectorAll('.orders-table__row');
    const orderListEmptyShow = document.querySelector('.order-list-empty-show');
    const ordersTableHide = document.querySelector('.orders-table-hide');

    function filterOrders() {
        const searchTerm = orderNumberInput.value.toLowerCase();
        const dateFilter = showSelect.value;
        let arr = [];
        
        orderRows.forEach(row => {
            const netsuiteOrderNumber = row.querySelector('.netsuite-order-number').textContent.toLowerCase();
            const purchaseOrderNumber = row.querySelector('.purchase-order-number').textContent.toLowerCase();
            const dateText = row.querySelector('.date').textContent.replace('Date:', '').trim();
            const orderDate = parseDate(dateText);
            

            let showByDate = true;
            
            // Apply date filtering
            if (dateFilter !== 'all') {
                const months = {
                    'three': 3,
                    'six': 6,
                    'year': 12
                }[dateFilter];
                
                showByDate = isWithinMonths(orderDate, months);
            }

            // Show/hide based on both filters
            const matchesSearch = netsuiteOrderNumber.includes(searchTerm) || 
                                purchaseOrderNumber.includes(searchTerm);
            row.style.display = (matchesSearch && showByDate) ? '' : 'none';
            if(matchesSearch && showByDate){
                arr.push(row);
            }
        });
        if(arr.length == 0) {
            ordersTableHide.style.display = 'none';
            orderListEmptyShow.style.display = 'block';
        } else {
            ordersTableHide.style.display = 'table';
            orderListEmptyShow.style.display = 'none';
        }
        // console.log(arr.length);

        // console.log(orderRows);

        // updateEmptyState();
    }

    function parseDate(dateString) {
        // Remove any extra spaces and split
        const parts = dateString.trim().split(' ');
        const day = parseInt(parts[0], 10);
        const month = getMonthNumber(parts[1]);
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    }

    function getMonthNumber(monthName) {
        const months = {
            'January': 0,
            'February': 1,
            'March': 2,
            'April': 3,
            'May': 4,
            'June': 5,
            'July': 6,
            'August': 7,
            'September': 8,
            'October': 9,
            'November': 10,
            'December': 11
        };
        return months[monthName];
    }

    function isWithinMonths(orderDate, months) {
        const today = new Date();
        const monthsAgo = new Date();
        
        // Move back X months
        monthsAgo.setMonth(today.getMonth() - months);
        
        // For debugging
        console.log('Order Date:', orderDate);
        console.log('Months Ago:', monthsAgo);
        console.log('Today:', today);
        
        return orderDate >= monthsAgo;
    }

    function updateEmptyState() {
        const visibleOrders = document.querySelectorAll('.orders-table__row[style=""]').length;
        let emptyState = document.querySelector('.orders-empty-state');
        
        if (visibleOrders === 0) {
            if (!emptyState) {
                emptyState = document.createElement('div');
                emptyState.className = 'orders-empty-state';
                emptyState.innerHTML = `
                    <p class="text-left py-8 text-gray-500">
                        No orders found
                    </p>
                `;
                ordersTable.appendChild(emptyState);
            }
        } else if (emptyState) {
            emptyState.remove();
        }
    }

    // Add event listeners
    if (orderNumberInput) {
        console.log(orderNumberInput);
        orderNumberInput.addEventListener('input', filterOrders);
    }

    if (showSelect) {
        showSelect.addEventListener('change', filterOrders);
    }

    // Shipping Method
    const shippingOptions = document.querySelectorAll(".shipping-option");
    const shippingCost = document.getElementById("shipping-cost");
    const orderTotal = document.getElementById("order-total");
    if (shippingOptions) {
        shippingOptions.forEach(option => {
            option.addEventListener("click", function () {
                const shippingHandle = this.dataset.handle;
                const selectedInput = document.getElementById("selectedShippingMethod");
                const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                if (selectedInput.value !== shippingHandle) {
                    selectedInput.value = shippingHandle;

                    const formData = new FormData();
                    formData.append('CRAFT_CSRF_TOKEN', csrfToken);
                    formData.append('action', 'commerce/cart/update-cart');
                    formData.append('shippingMethodHandle', shippingHandle);

                    // Send AJAX request to update shipping method
                    fetch("/shop/cart", {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Accept': 'application/json',
                        },
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            shippingOptions.forEach(opt => opt.classList.remove("border-primary"));
                            option.classList.add("border-primary");
                            if (shippingCost) {
                                shippingCost.textContent = `$${data.cart?.totalShippingCost?.toFixed(2)}`;
                            }
                            if (orderTotal) {
                                orderTotal.textContent = `$${data.cart?.totalPrice?.toFixed(2)}`;
                            }
                        }
                    })
                    .catch(error => console.error("Error updating shipping:", error));
                }
            });
        });
    }
});

// Load more orders on customer orders page
document.addEventListener('DOMContentLoaded', function() {
    const loadMoreBtn = document.getElementById('load-more-orders');
    
    if (loadMoreBtn) {
        loadMoreBtn.addEventListener('click', async function() {
            const currentPage = parseInt(this.dataset.page);
            const pageSize = parseInt(this.dataset.pagesize);
            const totalOrders = parseInt(this.dataset.total);
            const offset = currentPage * pageSize;

            // Disable button while loading
            loadMoreBtn.disabled = true;
            loadMoreBtn.innerHTML = 'Loading...';

            try {
                const response = await fetch(`/actions/load-more-orders?offset=${offset}&limit=${pageSize}`, {
                    headers: {
                        'Accept': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                
                // Append new orders to container
                const ordersContainer = document.getElementById('orders-container');
                ordersContainer.insertAdjacentHTML('beforeend', data.html);

                // Update button page number
                this.dataset.page = currentPage + 1;

                // Hide button if we've loaded all orders
                if ((currentPage + 1) * pageSize >= totalOrders) {
                    loadMoreBtn.style.display = 'none';
                }
            } catch (error) {
                console.error('Error:', error);
                loadMoreBtn.innerHTML = 'Error loading orders. Try again.';
            } finally {
                loadMoreBtn.disabled = false;
                if (loadMoreBtn.innerHTML === 'Loading...') {
                    loadMoreBtn.innerHTML = 'Load More Orders';
                }
            }
        });
    }
});