import { algoliasearch } from 'algoliasearch';
import { autocomplete, getAlgoliaResults, getAlgoliaFacets } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import '@algolia/autocomplete-theme-classic';
import '../css/components/search.pcss';

const currentUserCompanyNsId = window.currentUserCompanyNsId;

let searchClient;
let isLoaded = false;
let hits = [];
let nbHits = 0;

const searchButton = document.querySelectorAll('button.search-trigger');
const openSearch = document.querySelector('.cta-container .search-btn');
const searchContainer = document.querySelector('#autocomplete');
const closeSearch = document.querySelector('.search-container .close-search');
const bodyOverlay = document.body.querySelector('.body-overlay');


searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APPLICATION_ID,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY
);

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient,
  indexName: import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT + '_query_suggestions',
  getSearchParams({ state }) {
    return { hitsPerPage: state.query ? 5 : 10 };
  },
  categoryAttribute: [
    'instant_search',
    'facets',
    'exact_matches',
    'categories',
  ],
  itemsWithCategories: 2,
  categoriesPerItem: 2,
  transformSource({ source, onTapAhead }) {
    return {
      ...source,
      onSelect({ setIsOpen }) {
        setIsOpen(true);
      },
      templates: {
        header({ html }) {
          return html`Suggestions`;
        },
        item(params) {
          const { item, html } = params;
          return html`
            <div class="aa-ItemLink" data-item-name="${item.query}">
              ${source.templates.item(params).props.children}
            </div>
          `;
        },
        noResults({ html }) {
          return html`No results.`;
        },
      },
    };
  },
});

const autoCompleteInstance = autocomplete({
  container: searchContainer,
  panelContainer: '.aa-panel-container',
  plugins: [querySuggestionsPlugin],
  panelPlacement: 'full-width',
  placeholder: 'Search for products',
  debug: true,
  openOnFocus: true,
  detachedMediaQuery: 'none',
  getSources({ query }) {
    return [
      {
        // Categories source
        sourceId: 'categories',
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT,
                query,
                params: {
                  hitsPerPage: 1,
                },
              },
            ],
          });
        },
        templates: {
          item({ item, html }) {
            if(item.main_category) {
              return html`
                <div class="aa-category-ItemWrapper">
                  <div class="aa-main-category">
                    <a href="/${item.main_category.slug}">
                      ${item.main_category.title}
                    </a>
                  </div>
                  <div class="aa-additional-categories">
                    ${item.additional_categories.map(category => {
                      return html`
                        <a href="/${item.main_category.slug}?productCategory=${category.id}">
                          ${category.title}
                        </a>
                      `;
                    })}
                  </div>
                </div>
              `;
            } else {
              return html`
                <div class="aa-category-ItemWrapper">
                  
                </div>
              `;
            }
          },
          // header({ html }) {
          //   return html`Categories`;
          // },
          // item({ item, html }) {
          //   return html`<div class="aa-category-ItemWrapper">
          //     <div class="aa-ItemContent">
          //       <div class="aa-ItemContentBody">
          //         <div class="aa-ItemContentTitle">
          //           <a href="${item.url}">${item.title}</a>
          //         </div>
          //       </div>
          //       <div class="aa-ItemContentLink">
          //         <a href="${item.url}">
          //           <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
          //             <path d="M1.00195 1.57812L5.00036 5.57812L1.00195 9.57812" stroke="#283343" stroke-linecap="square" stroke-linejoin="round"/>
          //           </svg>
          //         </a>
          //       </div>
          //     </div>
          //   </div>`;
          // },
          footer({ html, state, items }) {
            if(items.length > 0) {
              return html`
                <a href="/search-results/?s=${state.query}" class="btn btn-primary">See All Product Results</a>
              `;
            }
          },
          noResults({ html }) {
            return html``;
          },
        },
      },
      {
        // Products source
        sourceId: 'products',
        getItems() {
          // Define search parameters
          const searchParams = {
            query,
            hitsPerPage: 6,
            clickAnalytics: true
          };
          
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT,
                params: searchParams,
              },
            ],
            transformResponse({ hits }) {
              // Filter hits based on company_ids and custom_product flag
              const filteredHits = hits[0].filter(hit => {

                // Skip custom products without assigned company
                if (hit.custom_product === false) {
                  return true;
                }

                // Convert currentUserCompanyNsId to string for comparison
                const userCompanyIdStr = String(currentUserCompanyNsId);

                // Include hit if company_ns_ids is empty or includes currentUserCompanyNsId
                return hit.company_ns_ids.includes(userCompanyIdStr);
              });
              
              // Return the filtered hits directly as the first element of the hits array
              hits[0] = filteredHits;
              
              return hits;
            }
          });
        },
        templates: {
          header({ html }) {
            return html`<div class="aa-SourceHeader">Products</div>`;
          },
          item({ item, html }) {
            const imageUrl = item.featuredImage?.url || '';
            return html`
            <a href="${item.url}">
            <div class="aa-product-ItemWrapper">
              <div class="aa-ItemContent">
                <div class="aa-ItemImage aa-ItemIcon--alignTop">
                  ${imageUrl != '' ? html`
                  <picture>
                    <source srcset="${imageUrl} 1x, ${imageUrl} 2x" media="(max-width:3000px)" type="image/webp" />
                    <source srcset="${imageUrl} 1x, ${imageUrl} 2x" media="(max-width:1440px)" type="image/webp" />
                    <source srcset="${imageUrl} 1x, ${imageUrl} 2x" media="(max-width:960px)" type="image/webp" />
                    <source srcset="${imageUrl} 1x, ${imageUrl} 2x" media="(max-width:576px)" type="image/webp" />
                    <img src="${imageUrl}" alt="${item.title}" />
                  </picture>
                  ` : html`
                    <div class="img-placeholder bg-gray-200 flex items-center justify-center">
                      <i class="fa-light fa-image text-[60px] text-gray-500"></i>
                    </div>
                  `}
                </div>
                <div class="aa-ItemContentBody">
                  <div class="aa-ItemContentTitle">
                    ${item.title}
                  </div>
                </div>
                <div class="aa-ItemContentLink">
                  <a>
                    View Product
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                      <path d="M1.00195 1.57812L5.00036 5.57812L1.00195 9.57812" stroke="#283343" style="stroke:#283343;stroke:color(display-p3 0.1569 0.2000 0.2627);stroke-opacity:1;" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            </a>
            `;
          },
          noResults({ html }) {
            return html`No results.`;
          },
        },
      },
    ];
  },
});

function closeAutocomplete() {
  autoCompleteInstance.setIsOpen(false);
}

const closeSearchBtn = document.querySelector('#closeSearchBtn');

if(closeSearchBtn) {
  closeSearchBtn.addEventListener('click', function() {
    closeAutocomplete();
  });
}


if (openSearch) {
  openSearch.addEventListener('click', () => {
    searchContainer.classList.add('active');
    searchContainer.querySelector('input').focus();
    document.body.classList.add('overlay-active');
  });
}
if (closeSearch) {
  closeSearch.addEventListener('click', () => {
    closeSearchContainer();
  });
}
if (bodyOverlay) {
  bodyOverlay.addEventListener('click', () => {
    closeSearchContainer();
  });
}

function closeSearchContainer() {
  searchContainer.classList.remove('active');
  searchContainer.querySelector('input').value = '';
  searchResults.innerHTML = '';
  searchResults.classList.remove('results-showed');
  document.body.classList.remove('overlay-active');
}

searchButton.forEach(button => {
  button.addEventListener('click', () => {
    searchContainer.querySelector('input.aa-Input').focus();
  });
});