import '../css/app.pcss';
import '../js/slider';
import 'lazysizes';
import './fancybox';
import './utils';
import './header';
import './megaMenu';
import './mobileMenu';
import './productModal';
import './search';
import './a-search-autocomplete';
import './shopFilter';
import './products';
import './accordian';
import './notify-email-modal'; 
import './components/register-tabs';
import './account';
import './share';
import './orders';
import './phone-validation';
import { VariantInfoTabs } from './components/variant-info-tabs';
import './components/anchor-tabs';
// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

document.addEventListener('DOMContentLoaded', () => {
    new VariantInfoTabs();
});