export class VariantInfoTabs {
    constructor() {
        this.init();
    }

    init() {
        const tabList = document.querySelector('.tab-list');
        if (!tabList) return;

        const tabs = tabList.querySelectorAll('.tab-button');
        const panels = document.querySelectorAll('.tab-panel');

        // Get tab index from URL
        const urlParams = new URLSearchParams(window.location.search);
        const tabIndex = urlParams.has('tab') ? parseInt(urlParams.get('tab'), 10) - 1 : 0; // Convert to zero-based index

        if (tabs.length > 0 && panels.length > 0) {
            // Ensure the correct tab is active on load
            const activeTabIndex = (tabIndex >= 0 && tabIndex < tabs.length) ? tabIndex : 0;

            // Remove all active states
            tabs.forEach(t => t.classList.remove('active'));
            panels.forEach(p => p.classList.remove('active'));

            // Set active state for the tab from the URL
            tabs[activeTabIndex].classList.add('active');
            tabs[activeTabIndex].setAttribute('aria-selected', 'true');
            panels[activeTabIndex].classList.add('active');
        }

        // Tab click event listener
        tabs.forEach((tab, index) => {
            tab.addEventListener('click', () => {
                // Remove active class from all tabs and panels
                tabs.forEach(t => {
                    t.classList.remove('active');
                    t.setAttribute('aria-selected', 'false');
                });

                panels.forEach(p => p.classList.remove('active'));

                // Add active class to clicked tab and its panel
                tab.classList.add('active');
                tab.setAttribute('aria-selected', 'true');

                const panelId = tab.getAttribute('aria-controls');
                const panel = document.getElementById(panelId);
                if (panel) {
                    panel.classList.add('active');
                }

                // Update URL without reloading the page
                const newUrl = new URL(window.location);
                newUrl.searchParams.set('tab', index + 1); // Store as 1-based index
                window.history.replaceState({}, '', newUrl);
            });
        });
    }
}

// Initialize tabs
document.addEventListener('DOMContentLoaded', () => {
    new VariantInfoTabs();
});
